import axios from 'axios';
import qs from 'qs';
import Vue from 'vue';
import { deepHashSnakeCase, deepHashCamelCase } from './convert_keys';

const httpClient = axios.create({
  transformRequest: [
    (data => (data ? deepHashSnakeCase(data) : undefined)),
    ...axios.defaults.transformRequest,
  ],
  transformResponse: [...axios.defaults.transformResponse, deepHashCamelCase],
  paramsSerializer: params => qs.stringify(deepHashSnakeCase(params), { arrayFormat: 'brackets', skipNulls: true }),
});

const csrf = document.querySelector('meta[name="csrf-token"]');
if (csrf) {
  httpClient.defaults.headers.common = {
    'X-CSRF-TOKEN': csrf.getAttribute('content'),
  };
}

httpClient.interceptors.response.use(response => response, (error) => {
  if (!error.response || !error.response.status) {
    window.EventBus.$emit('server-error', {});
    return Promise.reject(error);
  }

  if (error.response.status === 422 && error.config.csrfRefetch !== true) {
    return Vue.auth.fetch().then((csrfToken) => {
      error.config.headers['X-CSRF-TOKEN'] = csrfToken;
      httpClient.defaults.headers.common = { 'X-CSRF-TOKEN': csrfToken };
      document.querySelector('meta[name="csrf-token"]')?.setAttribute('content', csrfToken);
      error.config.csrfRefetch = true;
      return httpClient.request(error.config);
    });
  } if (error.response.status === 429) {
    window.EventBus.$emit('too-many-requests-error', {});
  } else if (error.response.status === 403) {
    window.EventBus.$emit('forbidden-error', {});
  } else if (error.response.status === 404 && error.response.data.error === 'NotFoundError') {
    window.EventBus.$emit('not-found-error', {});
  } else if (error.response.status === 401) {
    window.EventBus.$emit('unauthorized-error', {});
  } else if (error.response.status === 503) {
    window.EventBus.$emit('timeout-error', {});
  } else if (error.response.status >= 500) {
    window.EventBus.$emit('server-error', {});
  }

  return Promise.reject(error.response.data);
});


export default httpClient;
