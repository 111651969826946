import Vue from 'vue';
import VueI18n from 'vue-i18n';
// eslint-disable-next-line import/no-unresolved
import enLocale from 'en.yml';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: enLocale,
});

export default i18n;
