import Appsignal from '@appsignal/javascript';
import { errorHandler } from '@appsignal/vue';
import Vue from 'vue';

let appsignal;

if (process.env.RAILS_ENV === 'production' || process.env.RAILS_ENV === 'staging') {
  appsignal = new Appsignal({
    key: process.env.APPSIGNAL_FRONTEND_API_KEY,
    revision: process.env.SOURCE_VERSION,
  });
  Vue.config.errorHandler = errorHandler(appsignal, Vue);
}

function reportError(error) {
  if (appsignal) {
    appsignal.sendError(error);
  } else {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

const errorReporting = {
  install() {
    Vue.reportError = reportError;
    Vue.prototype.$reportError = reportError;

    Vue.appsignal = appsignal;
    Vue.prototype.$appsignal = appsignal;
  },
};


Vue.use(errorReporting, {});
